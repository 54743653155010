import registerReducer from "@/fe-core/_redux/reducers/userReducer/registerReducer";
import { SignupActions } from "./signup";
import { SignupSmsActions } from "./signupSms";
import { SignupEmailActions } from "./signupEmail";
import { ValidateActions } from "./validate";
import { SignupEmailConfirmationActions } from "./signupEmailConfirmation";
import { UserConsentsActions } from "./userConsents";

export type RegisterActions =
  | SignupActions
  | SignupSmsActions
  | SignupEmailConfirmationActions
  | SignupEmailActions
  | ValidateActions
  | UserConsentsActions;

export * from "./signup";
export * from "./signupEmail";
export * from "./signupSms";
export * from "./signupEmailConfirmation";
export * from "./validate";
export * from "./userConsents";

export type RegisterState = ReturnType<typeof registerReducer>;
