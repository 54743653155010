import userReducer from "@/fe-core/_redux/reducers/userReducer";
import { BalanceActions } from "./balance";
import { CashbackBalanceActions } from "./cashback";
import { ConsentsActions } from "./consents";
import { LimitsActions } from "./limits";
import { PasswordActions } from "./password";
import { ProfileActions } from "./profile";
import { RegisterActions } from "./register";
import { RolesActions } from "./roles";
import { BettingHistoryActions } from "./bettingHistory";
import { CasinoHistoryActions } from "./casinoHistory";
import { BonusActions } from "./bonus";
import { FavoritesActions } from "./favorites";
import { RealityCheckActions } from "./realityCheck";
import { CashierActions } from "./cashier";
import { ExternalCashierActions } from "./externalCashier";
import { SelfExclusionActions } from "./selfExclusion";
import { DocumentsActions } from "./documents";
import { RegionsActions } from "./regions";
import { CoolOffActions } from "./coolOff";
import { RestrictionsActions } from "./restrictions";
import { RecentPlayedActions } from "./recentPlayed";
import { BalanceWarningActions } from "./balanceWarning";
import { GameSessionsActions } from "./gameSessions";
import { RecommendedActions } from "@/fe-core/meta/types/user/recommended";
import { SessionLimitActions } from "./sessionLimit";
import { ProcessDepositActions } from "./cashier/processDeposit";
import { TagsActions } from "./tags";

export enum userActionTypes {
  GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE",
  CLEAR_PROFILE = "CLEAR_PROFILE",

  PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST",
  PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS",
  PROFILE_UPDATE_FAILURE = "PROFILE_UPDATE_FAILURE",

  SET_IS_USER_PROFILE_NEED_TO_UPDATED = "SET_IS_USER_PROFILE_NEED_TO_UPDATED",

  SET_SHOW_PROFILE_UPDATE_MODAL = "SET_SHOW_PROFILE_UPDATE_MODAL",
  SET_HIDE_PROFILE_UPDATE_MODAL = "SET_HIDE_PROFILE_UPDATE_MODAL",

  GET_LEVEL_UP_DETAILS_REQUEST = "GET_LEVEL_UP_DETAILS_REQUEST",
  GET_LEVEL_UP_DETAILS_SUCCESS = "GET_LEVEL_UP_DETAILS_SUCCESS",
  GET_LEVEL_UP_DETAILS_FAILURE = "GET_LEVEL_UP_DETAILS_FAILURE",

  GET_CASHBACK_BALANCE_REQUEST = "GET_CASHBACK_BALANCE_REQUEST",
  GET_CASHBACK_BALANCE_SUCCESS = "GET_CASHBACK_BALANCE_SUCCESS",
  GET_CASHBACK_BALANCE_FAILURE = "GET_CASHBACK_BALANCE_FAILURE",
  CLEAR_CASHBACK_BALANCE = "CLEAR_CASHBACK_BALANCE",

  REDEEM_CASHBACK_REQUEST = "REDEEM_CASHBACK_REQUEST",
  REDEEM_CASHBACK_SUCCESS = "REDEEM_CASHBACK_SUCCESS",
  REDEEM_CASHBACK_FAILURE = "REDEEM_CASHBACK_FAILURE",
  CLEAR_CASHBACK_MESSAGE_REQUEST = "CLEAR_CASHBACK_MESSAGE_REQUEST",
  CLEAR_REDEEM_CASHBACK_MESSAGE = "CLEAR_REDEEM_CASHBACK_MESSAGE",

  GET_BALANCE_REQUEST = "GET_BALANCE_REQUEST",
  GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS",
  GET_BALANCE_FAILURE = "GET_BALANCE_FAILURE",
  CLEAR_BALANCE = "CLEAR_BALANCE",
  SUBSCRIBE_WATCH_BALANCE = "SUBSCRIBE_WATCH_BALANCE",

  BALANCE_CHANGED = "BALANCE_CHANGED",

  BALANCE_UPDATE = "BALANCE_UPDATE",

  SET_SHOW_CHANGE_PASSWORD_MODAL = "SET_SHOW_CHANGE_PASSWORD_MODAL",
  SET_HIDE_CHANGE_PASSWORD_MODAL = "SET_HIDE_CHANGE_PASSWORD_MODAL",
  CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE",
  RESET_PASSWORD_SEND_EMAIL_REQUEST = "RESET_PASSWORD_SEND_EMAIL_REQUEST",
  RESET_PASSWORD_SEND_EMAIL_SUCCESS = "RESET_PASSWORD_SEND_EMAIL_SUCCESS",
  RESET_PASSWORD_SEND_EMAIL_FAILURE = "RESET_PASSWORD_SEND_EMAIL_FAILURE",
  RESET_PASSWORD_IS_KEY_AVAILABLE_REQUEST = "RESET_PASSWORD_IS_KEY_AVAILABLE_REQUEST",
  RESET_PASSWORD_IS_KEY_AVAILABLE_SUCCESS = "RESET_PASSWORD_IS_KEY_AVAILABLE_SUCCESS",
  RESET_PASSWORD_IS_KEY_AVAILABLE_FAILURE = "RESET_PASSWORD_IS_KEY_AVAILABLE_FAILURE",
  RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE",

  GET_CONSENTS_REQUEST = "GET_CONSENTS_REQUEST",
  GET_CONSENTS_SUCCESS = "GET_CONSENTS_SUCCESS",
  GET_CONSENTS_FAILURE = "GET_CONSENTS_FAILURE",
  SET_CONSENTS_REQUEST = "SET_CONSENTS_REQUEST",
  SET_CONSENTS_SUCCESS = "SET_CONSENTS_SUCCESS",
  SET_CONSENTS_FAILURE = "SET_CONSENTS_FAILURE",
  CLEAR_CONSENTS = "CLEAR_CONSENTS",

  GET_LIMITS_REQUEST = "GET_LIMITS_REQUEST",
  GET_LIMITS_SUCCESS = "GET_LIMITS_SUCCESS",
  GET_LIMITS_FAILURE = "GET_LIMITS_FAILURE",
  SET_DEPOSIT_LIMITS_REQUEST = "SET_DEPOSIT_LIMITS_REQUEST",
  SET_DEPOSIT_LIMITS_SUCCESS = "SET_DEPOSIT_LIMITS_SUCCESS",
  SET_DEPOSIT_LIMITS_FAILURE = "SET_DEPOSIT_LIMITS_FAILURE",
  DELETE_DEPOSIT_LIMITS_REQUEST = "DELETE_DEPOSIT_LIMITS_REQUEST",
  DELETE_DEPOSIT_LIMITS_SUCCESS = "DELETE_DEPOSIT_LIMITS_SUCCESS",
  DELETE_DEPOSIT_LIMITS_FAILURE = "DELETE_DEPOSIT_LIMITS_FAILURE",
  SET_LOSS_LIMITS_REQUEST = "SET_LOSS_LIMITS_REQUEST",
  SET_LOSS_LIMITS_SUCCESS = "SET_LOSS_LIMITS_SUCCESS",
  SET_LOSS_LIMITS_FAILURE = "SET_LOSS_LIMITS_FAILURE",
  DELETE_LOSS_LIMITS_REQUEST = "DELETE_LOSS_LIMITS_REQUEST",
  DELETE_LOSS_LIMITS_SUCCESS = "DELETE_LOSS_LIMITS_SUCCESS",
  DELETE_LOSS_LIMITS_FAILURE = "DELETE_LOSS_LIMITS_FAILURE",
  CLEAR_LIMITS = "CLEAR_LIMITS",

  PASSWORD_POLICY_REQUEST = "PASSWORD_POLICY_REQUEST",
  PASSWORD_POLICY_SET = "PASSWORD_POLICY_SET",

  UPDATE_SIGNUP_PAYLOAD = "UPDATE_SIGNUP_PAYLOAD",
  CLEAR_SIGNUP_PAYLOAD = "CLEAR_SIGNUP_PAYLOAD",
  CLEAR_SIGNUP_ERROR = "CLEAR_SIGNUP_ERROR",
  SET_SHOW_SIGNUP_MODAL = "SET_SHOW_SIGNUP_MODAL",
  SET_HIDE_SIGNUP_MODAL = "SET_HIDE_SIGNUP_MODAL",
  SET_ACTIVE_SIGNUP_SCREEN = "SET_ACTIVE_SIGNUP_SCREEN",

  SIGNUP_USERNAME_CHECK_REQUEST = "SIGNUP_USERNAME_CHECK_REQUEST",
  SIGNUP_USERNAME_CHECK_SUCCESS = "SIGNUP_USERNAME_CHECK_SUCCESS",
  SIGNUP_USERNAME_CHECK_FAILURE = "SIGNUP_USERNAME_CHECK_FAILURE",

  SIGNUP_REQUEST = "SIGNUP_REQUEST",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
  SIGNUP_FAILURE = "SIGNUP_FAILURE",

  CHECK_CREDENTIAL_REQUEST = "CHECK_CREDENTIAL_REQUEST",
  CHECK_CREDENTIAL_EMAIL_SUCCESS = "CHECK_CREDENTIAL_EMAIL_SUCCESS",
  CHECK_CREDENTIAL_EMAIL_FAILURE = "CHECK_CREDENTIAL_EMAIL_FAILURE",
  CHECK_CREDENTIAL_MOBILE_SUCCESS = "CHECK_CREDENTIAL_MOBILE_SUCCESS",
  CHECK_CREDENTIAL_MOBILE_FAILURE = "CHECK_CREDENTIAL_MOBILE_FAILURE",

  QUICK_SIGNUP_REQUEST = "QUICK_SIGNUP_REQUEST",
  QUICK_SIGNUP_SUCCESS = "QUICK_SIGNUP_SUCCESS",
  QUICK_SIGNUP_FAILURE = "QUICK_SIGNUP_FAILURE",

  VALIDATE_CNP_REQUEST = "VALIDATE_CNP_REQUEST",
  VALIDATE_CNP_SUCCESS = "VALIDATE_CNP_SUCCESS",
  VALIDATE_CNP_FAILURE = "VALIDATE_CNP_FAILURE",

  VALIDATE_USERNAME_REQUEST = "VALIDATE_USERNAME_REQUEST",
  VALIDATE_USERNAME_SUCCESS = "VALIDATE_USERNAME_SUCCESS",
  VALIDATE_USERNAME_FAILURE = "VALIDATE_USERNAME_FAILURE",

  VALIDATE_EMAIL_REQUEST = "VALIDATE_EMAIL_REQUEST",
  VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS",
  VALIDATE_EMAIL_FAILURE = "VALIDATE_EMAIL_FAILURE",

  VALIDATE_SIGNUP_IP_REQUEST = "VALIDATE_SIGNUP_IP_REQUEST",
  VALIDATE_SIGNUP_IP_SUCCESS = "VALIDATE_SIGNUP_IP_SUCCESS",
  VALIDATE_SIGNUP_IP_FAILURE = "VALIDATE_SIGNUP_IP_FAILURE",
  RESET_SIGNUP_PRE_CHECK = "RESET_SIGNUP_PRE_CHECK",

  VALIDATE_MOBILE_REQUEST = "VALIDATE_MOBILE_REQUEST",
  VALIDATE_MOBILE_SUCCESS = "VALIDATE_MOBILE_SUCCESS",
  VALIDATE_MOBILE_FAILURE = "VALIDATE_MOBILE_FAILURE",

  GET_USER_CONSENTS_REQUEST = "GET_USER_CONSENTS_REQUEST",
  GET_USER_CONSENTS_SUCCESS = "GET_USER_CONSENTS_SUCCESS",
  GET_USER_CONSENTS_FAILURE = "GET_USER_CONSENTS_FAILURE",

  GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST",
  GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS",
  SET_DETECTED_CURRENCY = "SET_DETECTED_CURRENCY",
  GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE",

  GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE",

  GET_VALID_COUNTRIES_REQUEST = "GET_VALID_COUNTRIES_REQUEST",
  GET_VALID_COUNTRIES_SUCCESS = "GET_VALID_COUNTRIES_SUCCESS",
  GET_VALID_COUNTRIES_FAILURE = "GET_VALID_COUNTRIES_FAILURE",

  SIGNUP_SMS_REQUEST = "SIGNUP_SMS_REQUEST",
  SIGNUP_SMS_SUCCESS = "SIGNUP_SMS_SUCCESS",
  SIGNUP_SMS_FAILURE = "SIGNUP_SMS_FAILURE",
  SIGNUP_SMS_RESEND_REQUEST = "SIGNUP_SMS_RESEND_REQUEST",
  SIGNUP_SMS_RESEND_SUCCESS = "SIGNUP_SMS_RESEND_SUCCESS",
  SIGNUP_SMS_RESEND_FAILURE = "SIGNUP_SMS_RESEND_FAILURE",
  CLEAR_SMS = "CLEAR_SMS",
  CLEAR_RESEND = "CLEAR_RESEND",

  SIGNUP_EMAIL_CONFIRMATION_REQUEST = "SIGNUP_EMAIL_CONFIRMATION_REQUEST",
  SIGNUP_EMAIL_CONFIRMATION_SUCCESS = "SIGNUP_EMAIL_CONFIRMATION_SUCCESS",
  SIGNUP_EMAIL_CONFIRMATION_FAILURE = "SIGNUP_EMAIL_CONFIRMATION_FAILURE",
  SIGNUP_EMAIL_CONFIRMATION_CLEAR_ERROR = "SIGNUP_EMAIL_CONFIRMATION_CLEAR_ERROR",

  SIGNUP_EMAIL_REQUEST = "SIGNUP_EMAIL_REQUEST",
  SIGNUP_RESEND_EMAIL_REQUEST = "SIGNUP_RESEND_EMAIL_REQUEST",
  SIGNUP_RESEND_EMAIL_SUCCESS = "SIGNUP_RESEND_EMAIL_SUCCESS",
  SIGNUP_RESEND_EMAIL_FAILURE = "SIGNUP_RESEND_EMAIL_FAILURE",

  GET_ROLES_REQUEST = "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE = "GET_ROLES_FAILURE",
  CLEAR_ROLES = "CLEAR_ROLES",

  UPDATE_TAGS_REQUEST = "UPDATE_TAGS_REQUEST",
  UPDATE_TAGS_SUCCESS = "UPDATE_TAGS_SUCCESS",
  UPDATE_TAGS_FAILURE = "UPDATE_TAGS_FAILURE",
  CLEAR_TAGS = "CLEAR_TAGS",

  BETTING_HISTORY_REQUEST = "BETTING_HISTORY_REQUEST",
  BETTING_HISTORY_SUCCESS = "BETTING_HISTORY_SUCCESS",
  BETTING_HISTORY_FAILURE = "BETTING_HISTORY_FAILURE",

  CASINO_HISTORY_REQUEST = "CASINO_HISTORY_REQUEST",
  CASINO_HISTORY_SUCCESS = "CASINO_HISTORY_SUCCESS",
  CASINO_HISTORY_FAILURE = "CASINO_HISTORY_FAILURE",
  GET_CASINO_HISTORY_MORE_REQUEST = "CASINO_HISTORY_MORE_REQUEST",
  GET_CASINO_HISTORY_MORE_SUCCESS = "GET_CASINO_HISTORY_MORE_SUCCESS",
  GET_CASINO_HISTORY_MORE_FAILURE = "GET_CASINO_HISTORY_MORE_FAILURE",

  GAME_SESSIONS_REQUEST = "GAME_SESSIONS_REQUEST",
  GAME_SESSIONS_SUCCESS = "GAME_SESSIONS_SUCCESS",
  GAME_SESSIONS_FAILURE = "GAME_SESSIONS_FAILURE",

  GET_REGIONS_REQUEST = "GET_REGIONS_REQUEST",
  GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS",
  GET_REGIONS_FAILURE = "GET_REGIONS_FAILURE",

  ENABLE_COOL_OFF_REQUEST = "ENABLE_COOL_OFF_REQUEST",
  ENABLE_COOL_OFF_FAILURE = "ENABLE_COOL_OFF_FAILURE",

  SESSION_LIMIT_REQUEST = "SESSION_LIMIT_REQUEST",
  SESSION_LIMIT_SUCCESS = "SESSION_LIMIT_SUCCESS",
  SESSION_LIMIT_FAILURE = "SESSION_LIMIT_FAILURE",

  GET_BONUSES_REQUEST = "GET_BONUSES_REQUEST",
  GET_BONUSES_SUCCESS = "GET_BONUSES_SUCCESS",
  GET_BONUSES_FAILURE = "GET_BONUSES_FAILURE",
  GET_CLAIMABLE_BONUSES_REQUEST = "GET_CLAIMABLE_BONUSES_REQUEST",
  GET_CLAIMABLE_BONUSES_SUCCESS = "GET_CLAIMABLE_BONUSES_SUCCESS",
  GET_CLAIMABLE_BONUSES_FAILURE = "GET_CLAIMABLE_BONUSES_FAILURE",
  APPLY_BONUS_REQUEST = "APPLY_BONUS_REQUEST",
  APPLY_BONUS_SUCCESS = "APPLY_BONUS_SUCCESS",
  APPLY_BONUS_FAILURE = "APPLY_BONUS_FAILURE",
  CANCEL_BONUS_REQUEST = "CANCEL_BONUS_REQUEST",
  CANCEL_BONUS_SUCCESS = "CANCEL_BONUS_SUCCESS",
  CANCEL_BONUS_FAILURE = "CANCEL_BONUS_FAILURE",
  CLEAR_CANCEL_BONUS_MESSAGE = "CLEAR_CANCEL_BONUS_MESSAGE",
  CLEAR_BONUS_MESSAGE_REQUEST = "CLEAR_BONUS_MESSAGE_REQUEST",

  DEPOSIT_ELIGIBLE_BONUS_PLANS_REQUEST = "DEPOSIT_ELIGIBLE_BONUS_PLANS_REQUEST",
  DEPOSIT_ELIGIBLE_BONUS_PLANS_SUCCESS = "DEPOSIT_ELIGIBLE_BONUS_PLANS_SUCCESS",
  DEPOSIT_ELIGIBLE_BONUS_PLANS_FAILURE = "DEPOSIT_ELIGIBLE_BONUS_PLANS_FAILURE",

  GET_ELIGIBLE_OPT_IN_BONUS_PLANS_REQUEST = "GET_ELIGIBLE_OPT_IN_BONUS_PLANS_REQUEST",
  GET_ELIGIBLE_OPT_IN_BONUS_PLANS_SUCCESS = "GET_ELIGIBLE_OPT_IN_BONUS_PLANS_SUCCESS",
  GET_ELIGIBLE_OPT_IN_BONUS_PLANS_FAILURE = "GET_ELIGIBLE_OPT_IN_BONUS_PLANS_FAILURE",

  OPT_IN_BONUS_REQUEST = "OPT_IN_BONUS_REQUEST",
  OPT_IN_BONUS_SUCCESS = "OPT_IN_BONUS_SUCCESS",
  OPT_IN_BONUS_FAILURE = "OPT_IN_BONUS_FAILURE",
  CLEAR_OPT_IN_BONUS_MESSAGE_REQUEST = "CLEAR_OPT_IN_BONUS_MESSAGE_REQUEST",

  REDEEM_BONUS_REQUEST = "REDEEM_BONUS_REQUEST",
  REDEEM_BONUS_SUCCESS = "REDEEM_BONUS_SUCCESS",
  REDEEM_BONUS_FAILURE = "REDEEM_BONUS_FAILURE",
  CLEAR_REDEEM_BONUS_MESSAGE = "CLEAR_REDEEM_BONUS_MESSAGE",

  GET_FAVORITES_REQUEST = "GET_FAVORITES_REQUEST",
  GET_FAVORITES_SUCCESS = "GET_FAVORITES_SUCCESS",
  GET_FAVORITES_FAILURE = "GET_FAVORITES_FAILURE",
  ADD_FAVORITE_REQUEST = "ADD_FAVORITE_REQUEST",
  ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS",
  ADD_FAVORITE_FAILURE = "ADD_FAVORITE_FAILURE",
  DELETE_FAVORITE_REQUEST = "DELETE_FAVORITE_REQUEST",
  DELETE_FAVORITE_SUCCESS = "DELETE_FAVORITE_SUCCESS",
  DELETE_FAVORITE_FAILURE = "DELETE_FAVORITE_FAILURE",
  CLEAR_FAVORITES = "CLEAR_FAVORITES",

  GET_RECENT_PLAYED_REQUEST = "GET_RECENT_PLAYED_REQUEST",
  GET_RECENT_PLAYED_SUCCESS = "GET_RECENT_PLAYED_SUCCESS",
  GET_RECENT_PLAYED_FAILURE = "GET_RECENT_PLAYED_FAILURE",

  GET_RECOMMENDED_REQUEST = "GET_RECOMMENDED_REQUEST",
  GET_RECOMMENDED_SUCCESS = "GET_RECOMMENDED_SUCCESS",
  GET_RECOMMENDED_FAILURE = "GET_RECOMMENDED_FAILURE",

  GET_LOGINS_REQUEST = "GET_LOGINS_REQUEST",
  GET_LOGINS_SUCCESS = "GET_LOGINS_SUCCESS",
  GET_LOGINS_FAILURE = "GET_LOGINS_FAILURE",

  SET_REALITY_CHECK = "SET_REALITY_CHECK",

  SET_BALANCE_WARNING = "SET_BALANCE_WARNING",

  SET_OPEN_DEPOSIT_MODAL = "SET_OPEN_DEPOSIT_MODAL",
  SET_CLOSE_DEPOSIT_MODAL = "SET_CLOSE_DEPOSIT_MODAL",
  DEPOSIT_REQUEST = "DEPOSIT_REQUEST",
  GET_DEPOSIT_METHODS_REQUEST = "GET_DEPOSIT_METHODS_REQUEST",
  GET_DEPOSIT_METHODS_SUCCESS = "GET_DEPOSIT_METHODS_SUCCESS",
  GET_DEPOSIT_METHODS_FAILURE = "GET_DEPOSIT_METHODS_FAILURE",

  GET_WITHDRAW_METHODS_REQUEST = "GET_WITHDRAW_METHODS_REQUEST",
  GET_WITHDRAW_METHODS_SUCCESS = "GET_WITHDRAW_METHODS_SUCCESS",
  GET_WITHDRAW_METHODS_FAILURE = "GET_WITHDRAW_METHODS_FAILURE",

  RESET_PROCESS_DEPOSIT_STATUS = "RESET_PROCESS_DEPOSIT_STATUS",
  OPEN_PROCESS_DEPOSIT_MODAL = "OPEN_PROCESS_DEPOSIT_MODAL",
  CLOSE_PROCESS_DEPOSIT_MODAL = "CLOSE_PROCESS_DEPOSIT_MODAL",

  PROCESS_DEPOSIT_REQUEST = "PROCESS_DEPOSIT_REQUEST",
  PROCESS_DEPOSIT_REQUEST_SUCCESS = "PROCESS_DEPOSIT_REQUEST_SUCCESS",
  PROCESS_DEPOSIT_REQUEST_FAILURE = "PROCESS_DEPOSIT_REQUEST_FAILURE",
  CLEAR_PROCESS_DEPOSIT = "CLEAR_PROCESS_DEPOSIT",
  CLEAR_PROCESS_DEPOSIT_MESSAGE = "CLEAR_PROCESS_DEPOSIT_MESSAGE",

  PREPARE_CASHIER_REQUEST = "PREPARE_CASHIER_REQUEST",
  PREPARE_CASHIER_REQUEST_SUCCESS = "PREPARE_CASHIER_REQUEST_SUCCESS",
  PREPARE_CASHIER_REQUEST_FAILURE = "PREPARE_CASHIER_REQUEST_FAILURE",
  CLEAR_PREPARE_CASHIER = "CLEAR_PREPARE_CASHIER",

  RESET_PROCESS_WITHDRAW_STATUS = "RESET_PROCESS_WITHDRAW_STATUS",
  OPEN_PROCESS_WITHDRAW_MODAL = "OPEN_PROCESS_WITHDRAW_MODAL",
  CLOSE_PROCESS_WITHDRAW_MODAL = "CLOSE_PROCESS_WITHDRAW_MODAL",

  PROCESS_WITHDRAW_REQUEST = "PROCESS_WITHDRAW_REQUEST",
  PROCESS_WITHDRAW_REQUEST_SUCCESS = "PROCESS_WITHDRAW_REQUEST_SUCCESS",
  PROCESS_WITHDRAW_REQUEST_FAILURE = "PROCESS_WITHDRAW_REQUEST_FAILURE",
  
  PREPARE_WITHDRAW_REQUEST = "PREPARE_WITHDRAW_REQUEST",
  PREPARE_WITHDRAW_REQUEST_SUCCESS = "PREPARE_WITHDRAW_REQUEST_SUCCESS",
  PREPARE_WITHDRAW_REQUEST_FAILURE = "PREPARE_WITHDRAW_REQUEST_FAILURE",

  CLEAR_PROCESS_WITHDRAW = "CLEAR_PROCESS_WITHDRAW",
  DEPOSIT_SUCCESS = "DEPOSIT_SUCCESS",
  DEPOSIT_FAILURE = "DEPOSIT_FAILURE",
  CLEAR_DEPOSIT = "CLEAR_DEPOSIT",

  DEPOSIT_BONUSES_REQUEST = "DEPOSIT_BONUSES_REQUEST",
  DEPOSIT_BONUSES_SUCCESS = "DEPOSIT_BONUSES_SUCCESS",
  DEPOSIT_BONUSES_FAILURE = "DEPOSIT_BONUSES_FAILURE",

  WITHDRAW_REQUEST = "WITHDRAW_REQUEST",
  WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS",
  WITHDRAW_FAILURE = "WITHDRAW_FAILURE",
  CLEAR_WITHDRAW = "CLEAR_WITHDRAW",

  PENDING_WITHDRAWALS_REQUEST = "PENDING_WITHDRAWALS_REQUEST",
  PENDING_WITHDRAWALS_SUCCESS = "PENDING_WITHDRAWALS_SUCCESS",
  PENDING_WITHDRAWALS_FAILURE = "PENDING_WITHDRAWALS_FAILURE",

  CANCEL_PENDING_WITHDRAWAL_REQUEST = "CANCEL_PENDING_WITHDRAWAL_REQUEST",
  CANCEL_PENDING_WITHDRAWAL_SUCCESS = "CANCEL_PENDING_WITHDRAWAL_SUCCESS",
  CANCEL_PENDING_WITHDRAWAL_FAILURE = "CANCEL_PENDING_WITHDRAWAL_FAILURE",
  CLEAR_PENDING_WITHDRAWALS = "CLEAR_PENDING_WITHDRAWALS",

  SELF_EXCLUSION_CONFIG_REQUEST = "SELF_EXCLUSION_CONFIG_REQUEST",
  SELF_EXCLUSION_CONFIG_SUCCESS = "SELF_EXCLUSION_CONFIG_SUCCESS",
  SELF_EXCLUSION_CONFIG_FAILURE = "SELF_EXCLUSION_CONFIG_FAILURE",

  SELF_EXCLUSION_REQUEST = "SELF_EXCLUSION_REQUEST",
  SELF_EXCLUSION_SUCCESS = "SELF_EXCLUSION_SUCCESS",
  SELF_EXCLUSION_FAILURE = "SELF_EXCLUSION_FAILURE",

  TRANSACTION_HISTORY_REQUEST = "TRANSACTION_HISTORY_REQUEST",
  TRANSACTION_HISTORY_SUCCESS = "TRANSACTION_HISTORY_SUCCESS",
  TRANSACTION_HISTORY_FAILURE = "TRANSACTION_HISTORY_FAILURE",
  CLEAR_TRANSACTION_HISTORY = "CLEAR_TRANSACTION_HISTORY",

  BANKING_HISTORY_REQUEST = "BANKING_HISTORY_REQUEST",
  BANKING_HISTORY_SUCCESS = "BANKING_HISTORY_SUCCESS",
  BANKING_HISTORY_FAILURE = "BANKING_HISTORY_FAILURE",

  TRANSACTION_SUMMARY_REQUEST = "TRANSACTION_SUMMARY_REQUEST",
  TRANSACTION_SUMMARY_SUCCESS = "TRANSACTION_SUMMARY_SUCCESS",
  TRANSACTION_SUMMARY_FAILURE = "TRANSACTION_SUMMARY_FAILURE",
  CLEAR_TRANSACTION_SUMMARY = "CLEAR_TRANSACTION_SUMMARY",

  PAYMENT_IQ_TRANSACTION_HISTORY_REQUEST = "PAYMENT_IQ_TRANSACTION_HISTORY_REQUEST", // EC: External Cashier
  PAYMENT_IQ_TRANSACTION_HISTORY_SUCCESS = "PAYMENT_IQ_TRANSACTION_HISTORY_SUCCESS",
  SET_PAYMENT_IQ_TRANSACTION_HISTORY_PENDING = "SET_PAYMENT_IQ_TRANSACTION_HISTORY_PENDING",
  PAYMENT_IQ_TRANSACTION_HISTORY_FAILURE = "PAYMENT_IQ_TRANSACTION_HISTORY_FAILURE",
  SET_PAYMENT_IQ_TRANSACTION_TIME_INTERVAL = "SET_PAYMENT_IQ_TRANSACTION_TIME_INTERVAL",
  CANCEL_PAYMENT_IQ_TRANSACTION_REQUEST = "CANCEL_PAYMENT_IQ_TRANSACTION_REQUEST",
  CANCEL_PAYMENT_IQ_TRANSACTION_SUCCESS = "CANCEL_PAYMENT_IQ_TRANSACTION_SUCCESS",
  CANCEL_PAYMENT_IQ_TRANSACTION_FAILURE = "CANCEL_PAYMENT_IQ_TRANSACTION_FAILURE",
  CLEAR_PAYMENT_IQ_TRANSACTION_HISTORY = "CLEAR_PAYMENT_IQ_TRANSACTION_HISTORY",

  GET_DOCUMENTS_REQUEST = "DOCUMENTS_STATUS_REQUEST",
  GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS",
  GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE",
  GET_USER_DOCUMENTS_REQUEST = "GET_USER_DOCUMENTS_REQUEST",
  GET_USER_DOCUMENTS_SUCCESS = "GET_USER_DOCUMENTS_SUCCESS",
  GET_USER_DOCUMENTS_FAILURE = "GET_USER_DOCUMENTS_FAILURE",
  UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST",
  UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_FAILURE = "UPLOAD_DOCUMENT_FAILURE",
  CLEAR_UPLOAD_DOCUMENT_STATUS = "CLEAR_UPLOAD_DOCUMENT_STATUS",

  OPEN_UPLOAD_DOCUMENT_MODAL = "OPEN_UPLOAD_DOCUMENT_MODAL",
  CLOSE_UPLOAD_DOCUMENT_MODAL = "CLOSE_UPLOAD_DOCUMENT_MODAL",

  CLEAR_DOCUMENTS = "CLEAR_DOCUMENTS",

  SET_TC_RESTRICTIONS = "SET_TC_RESTRICTIONS",
  RESOLVE_TC_RESTRICTION_REQUEST = "RESOLVE_TC_RESTRICTION_REQUEST",
  RESOLVE_TC_RESTRICTION_SUCCESS = "RESOLVE_TC_RESTRICTION_SUCCESS",
  RESOLVE_TC_RESTRICTION_FAILURE = "RESOLVE_TC_RESTRICTION_FAILURE",

  SET_MARKETING_RESTRICTIONS = "SET_MARKETING_RESTRICTIONS",

  SET_RESTRICTION_USER_DETAILS = "SET_RESTRICTION_USER_DETAILS",

  GET_TC_REQUIREMENTS_REQUEST = "GET_TC_REQUIREMENTS_REQUEST",
  GET_TC_REQUIREMENTS_SUCCESS = "GET_TC_REQUIREMENTS_SUCCESS",
  GET_TC_REQUIREMENTS_FAILURE = "GET_TC_REQUIREMENTS_FAILURE",

  GET_KYC_DOCUMENTS_REQUEST = "GET_KYC_DOCUMENTS_REQUEST",
  GET_KYC_DOCUMENTS_SUCCESS = "GET_KYC_DOCUMENTS_SUCCESS",
  GET_KYC_DOCUMENTS_FAILURE = "GET_KYC_DOCUMENTS_FAILURE",
  CLEAR_KYC_DOCUMENTS = "CLEAR_KYC_DOCUMENTS",

  GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST",
  GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS",
  GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE",
  SET_CAMPAIGN_REQUEST = "SET_CAMPAIGNS_REQUEST",
  SET_CAMPAIGN_SUCCESS = "SET_CAMPAIGNS_SUCCESS",
  SET_CAMPAIGN_FAILURE = "SET_CAMPAIGNS_FAILURE",
  CLEAR_CAMPAIGNS = "CLEAR_CAMPAIGNS",

  GET_AFFILIATE_REQUEST = "GET_AFFILIATE_REQUEST",
  GET_AFFILIATE_SUCCESS = "GET_AFFILIATE_SUCCESS",
  GET_AFFILIATE_FAILURE = "GET_AFFILIATE_FAILURE",

  POST_CLAIM_REQUEST = "POST_CLAIM_REQUEST",
  GET_CLAIM_REQUEST = "GET_AFFILIATE_REQUEST",
  GET_CLAIM_SUCCESS = "GET_CLAIM_SUCCESS",
  GET_CLAIM_FAILURE = "GET_CLAIM_FAILURE",
}

export type UserActions =
  | ProfileActions
  | BalanceActions
  | BonusActions
  | CashbackBalanceActions
  | PasswordActions
  | ConsentsActions
  | LimitsActions
  | RegisterActions
  | RolesActions
  | TagsActions
  | BettingHistoryActions
  | CasinoHistoryActions
  | FavoritesActions
  | RealityCheckActions
  | CashierActions
  | ExternalCashierActions
  | SelfExclusionActions
  | DocumentsActions
  | RegionsActions
  | CoolOffActions
  | RestrictionsActions
  | RecentPlayedActions
  | RecommendedActions
  | BalanceWarningActions
  | GameSessionsActions
  | SessionLimitActions
  | ProcessDepositActions;

export type UserState = ReturnType<typeof userReducer>;

export * from "./profile";
export * from "./balance";
export * from "./cashback";
export * from "./password";
export * from "./consents";
export * from "./register";
export * from "./roles";
export * from "./tags";
export * from "./bettingHistory";
export * from "./casinoHistory";
export * from "./bonus";
export * from "./favorites";
export * from "./realityCheck";
export * from "./cashier";
export * from "./externalCashier";
export * from "./selfExclusion";
export * from "./documents";
export * from "./coolOff";
export * from "./restrictions";
export * from "./recentPlayed";
export * from "./recommended";
export * from "./balanceWarning";
export * from "./logins";
//@todo refactor
export * from "./affiliate";
export * from "./campaigns";
export * from "./gameSessions";
export * from "./sessionLimit";
