import { userActionTypes } from '..'
import { IErrorPayload, IStatus } from '../../../interfaces/root'
import {
  IDepositRequestPayload,
  IDepositViewModel,
  IGetDepositMethodsResponse,
} from '../../../interfaces/user'

export enum depositStatus {
  Success = 'Success',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

export type SetOpenDepositModalAction = {
  type: userActionTypes.SET_OPEN_DEPOSIT_MODAL;
};

export type SetCloseDepositModalAction = {
  type: userActionTypes.SET_CLOSE_DEPOSIT_MODAL;
};

export type GetDepositMethodsRequestAction = {
  type: userActionTypes.GET_DEPOSIT_METHODS_REQUEST;
};
export type GetDepositMethodsSuccessAction = {
  type: userActionTypes.GET_DEPOSIT_METHODS_SUCCESS;
  payload: IGetDepositMethodsResponse
};
export type GetDepositMethodsFailureAction = {
  type: userActionTypes.GET_DEPOSIT_METHODS_FAILURE;
  payload: IErrorPayload
};

export type DepositRequestAction = {
  type: userActionTypes.DEPOSIT_REQUEST
  payload: IDepositRequestPayload
}

export type DepositSuccessAction = {
  type: userActionTypes.DEPOSIT_SUCCESS
  payload: IDepositViewModel
}

export type DepositFailureAction = {
  type: userActionTypes.DEPOSIT_FAILURE
  payload: IErrorPayload
}

export type ClearDepositAction = {
  type: userActionTypes.CLEAR_DEPOSIT
}

export type DepositActions =
  | SetOpenDepositModalAction
  | SetCloseDepositModalAction
  | DepositRequestAction
  | DepositSuccessAction
  | DepositFailureAction
  | ClearDepositAction
  | GetDepositMethodsRequestAction
  | GetDepositMethodsSuccessAction
  | GetDepositMethodsFailureAction

export type DepositState = {
  pending: boolean
  data: IDepositViewModel | null
  status: IStatus | null
  showDepositModal: boolean
  depositTempData: IDepositViewModel | null
  depositMethods: IGetDepositMethodsResponse | null
}
