import { userActionTypes } from '..'
import { IErrorPayload, IStatus } from '../../../interfaces/root'
import { IProcessWithdrawResponsePayload, IProcessWithdrawRequestPayload, IWithdrawMethod } from '../../../interfaces/user/cashier/withdraw';
import {
  IWithdrawViewModel,
  IGetWithdrawMethodsResponse,
} from '../../../interfaces/user'

export enum withdrawStatus {
  Success = 'Success',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

export type WithdrawRequestAction = {
  type: userActionTypes.WITHDRAW_REQUEST
  payload: number
}

export type WithdrawSuccessAction = {
  type: userActionTypes.WITHDRAW_SUCCESS
  payload: IWithdrawViewModel
}

export type WithdrawFailureAction = {
  type: userActionTypes.WITHDRAW_FAILURE
  payload: IErrorPayload
}

export type GetWithdrawMethodsRequestAction = {
  type: userActionTypes.GET_WITHDRAW_METHODS_REQUEST;
};
export type GetWithdrawMethodsSuccessAction = {
  type: userActionTypes.GET_WITHDRAW_METHODS_SUCCESS;
  payload: IGetWithdrawMethodsResponse
};
export type GetWithdrawMethodsFailureAction = {
  type: userActionTypes.GET_WITHDRAW_METHODS_FAILURE;
  payload: IErrorPayload
};
export type ResetProcessWithdrawStatusAction = {
  type: userActionTypes.RESET_PROCESS_WITHDRAW_STATUS;
};
export type OpenProcessWithdrawModalAction = {
  type: userActionTypes.OPEN_PROCESS_WITHDRAW_MODAL;
  payload: IWithdrawMethod;
};

export type CloseProcessWithdrawModalAction = {
  type: userActionTypes.CLOSE_PROCESS_WITHDRAW_MODAL;
};

export type ProcessWithdrawRequestAction = {
  type: userActionTypes.PROCESS_WITHDRAW_REQUEST;
  payload: IProcessWithdrawRequestPayload;
};

export type ProcessWithdrawSuccessAction = {
  type: userActionTypes.PROCESS_WITHDRAW_REQUEST_SUCCESS;
  payload: IProcessWithdrawResponsePayload;
};

export type ProcessWithdrawFailureAction = {
  type: userActionTypes.PROCESS_WITHDRAW_REQUEST_FAILURE;
  payload: IErrorPayload;
};
export type ClearWithdrawAction = {
  type: userActionTypes.CLEAR_WITHDRAW
}

export type WithdrawActions =
  | WithdrawRequestAction
  | WithdrawSuccessAction
  | WithdrawFailureAction
  | GetWithdrawMethodsRequestAction
  | GetWithdrawMethodsSuccessAction
  | GetWithdrawMethodsFailureAction
  | OpenProcessWithdrawModalAction
  | CloseProcessWithdrawModalAction
  | ProcessWithdrawRequestAction
  | ProcessWithdrawSuccessAction
  | ProcessWithdrawFailureAction
  | ClearWithdrawAction
  | ResetProcessWithdrawStatusAction

export type WithdrawState = {
  pending: boolean;
  data: IWithdrawViewModel | null;
  status: IStatus | null;
  withdrawMethods: IGetWithdrawMethodsResponse | null;

  selectedPaymentMethod: IWithdrawMethod | null;
  processWithdrawPending: boolean;
  processWithdrawStatus: IStatus | null | PrepareWithdrawCashierStatus;
};

export type PrepareWithdrawCashierStatus = { continueUrl?: string; status: string, authToken?: string };
