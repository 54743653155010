import cashierReducer from "@/fe-core/_redux/reducers/userReducer/cashierReducer";
import { DepositActions } from "./deposit";
import { DepositBonusesActions } from "./depositBonuses";
import { PendingWithdrawalsActions } from "./pendingWithdrawals";
import { TransactionHistoryActions } from "./transactionHistory";
import { TransactionSummaryActions } from "./transactionSummary";
import { WithdrawActions } from "./withdraw";

export enum transactionTypes {
  DEPOSIT = "Deposit",
  WITHDRAWAL = "Withdrawal",
  PENDING_WITHDRAWALS = "Pending Withdrawals",
}

export enum bankingHistoryTypes {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}

export type CashierActions =
  | DepositActions
  | DepositBonusesActions
  | WithdrawActions
  | PendingWithdrawalsActions
  | TransactionHistoryActions
    | TransactionSummaryActions;

export * from "./deposit";
export * from "./depositBonuses";
export * from "./withdraw";
export * from "./pendingWithdrawals";
export * from "./transactionHistory";
export * from "./bankingHistory";
export * from "./transactionSummary";

export type CashierState = ReturnType<typeof cashierReducer>;
