import { PaymentIqTransactionHistoryActions } from "./paymentIq/transactionHistory";
import ExternalCashierReducer from "@/fe-core/_redux/reducers/userReducer/externalCashierReducer";

export enum ecTransactionTypes {
  DEPOSIT = "Deposit",
  WITHDRAWAL = "Withdrawal",
  PENDING_WITHDRAWALS = "Pending Withdrawals",
}
export * from "./paymentIq/transactionHistory";

export type ExternalCashierActions = PaymentIqTransactionHistoryActions;


export type ExternalCashierState = ReturnType<typeof ExternalCashierReducer>;