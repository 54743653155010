import { userActionTypes } from "..";
import { IErrorPayload, IStatus } from "../../../interfaces/root";
import {
  ICheckCredentialRequestPayload,
  IQuickSignupPayload,
  ISignupLocalPayload,
  ISignupPayload,
  IUsernameCheckPayload,
} from "../../../interfaces/user/register";

export enum signupScreens {
  INITIAL_SCREEN = "INITIAL_SCREEN",
  DOCUMENT_INFORMATION = "DOCUMENT_INFORMATION",
  ACCOUNT_INFORMATION = "ACCOUNT_INFORMATION",
  SMS_CONFIRMATION = "SMS_CONFIRMATION",
  PERSONAL_INFORMATION = "PERSONAL_INFORMATION",
  EMAIL_CONFIRMATION = "EMAIL_CONFIRMATION",
  DOCUMENTNR_INFORMATION = "DOCUMENTNR_INFORMATION",
  TERMS_INFORMATION = "TERMS_INFORMATION",
}

export enum signupTitles {
  Dl = "Mr.",
  Dna = "Mrs.",
  Dra = "Miss",
}

export type SetShowSignupAction = {
  type: userActionTypes.SET_SHOW_SIGNUP_MODAL;
};

export type SetHideSignupAction = {
  type: userActionTypes.SET_HIDE_SIGNUP_MODAL;
};

export type SetActiveSignupScreenAction = {
  type: userActionTypes.SET_ACTIVE_SIGNUP_SCREEN;
  payload: signupScreens;
};

export type UpdateSignupPayloadAction = {
  type: userActionTypes.UPDATE_SIGNUP_PAYLOAD;
  payload: ISignupLocalPayload;
};

export type ClearSignupPayloadAction = {
  type: userActionTypes.CLEAR_SIGNUP_PAYLOAD;
};

export type ClearSignupErrorAction = {
  type: userActionTypes.CLEAR_SIGNUP_ERROR;
};

export type SignupRequestAction = {
  type: userActionTypes.SIGNUP_REQUEST;
  payload: ISignupPayload;
};

export type SignupUsernameCheckAction = {
  type: userActionTypes.SIGNUP_USERNAME_CHECK_REQUEST;
  payload: IUsernameCheckPayload;
};

export type SignupUsernameCheckSuccessAction = {
  type: userActionTypes.SIGNUP_USERNAME_CHECK_SUCCESS;
};

export type SignupUsernameCheckFailureAction = {
  type: userActionTypes.SIGNUP_USERNAME_CHECK_FAILURE;
  payload: IErrorPayload;
};

export type CheckCredentialRequestAction = {
  type: userActionTypes.CHECK_CREDENTIAL_REQUEST;
  payload: ICheckCredentialRequestPayload;
};

export type CheckCredentialEmailSuccessAction = {
  type: userActionTypes.CHECK_CREDENTIAL_EMAIL_SUCCESS;
};

export type CheckCredentialEmailFailureAction = {
  type: userActionTypes.CHECK_CREDENTIAL_EMAIL_FAILURE;
  payload: IErrorPayload;
};

export type CheckCredentialMobileSuccessAction = {
  type: userActionTypes.CHECK_CREDENTIAL_MOBILE_SUCCESS;
};

export type CheckCredentialMobileFailureAction = {
  type: userActionTypes.CHECK_CREDENTIAL_MOBILE_FAILURE;
  payload: IErrorPayload;
};

export type SignupSuccessAction = {
  type: userActionTypes.SIGNUP_SUCCESS;
};

export type SignupFailureAction = {
  type: userActionTypes.SIGNUP_FAILURE;
  payload: IErrorPayload;
};
export type QuickSignupRequestAction = {
  type: userActionTypes.QUICK_SIGNUP_REQUEST;
  payload: IQuickSignupPayload;
};

export type QuickSignupSuccessAction = {
  type: userActionTypes.QUICK_SIGNUP_SUCCESS;
};

export type QuickSignupFailureAction = {
  type: userActionTypes.QUICK_SIGNUP_FAILURE;
  payload: IErrorPayload;
};

export type SignupActions =
  | SetActiveSignupScreenAction
  | SetHideSignupAction
  | SetShowSignupAction
  | SignupRequestAction
  | SignupSuccessAction
  | SignupFailureAction
  | QuickSignupRequestAction
  | QuickSignupSuccessAction
  | QuickSignupFailureAction
  | UpdateSignupPayloadAction
  | ClearSignupPayloadAction
  | SignupUsernameCheckAction
  | SignupUsernameCheckSuccessAction
  | SignupUsernameCheckFailureAction
  | ClearSignupErrorAction
  | CheckCredentialRequestAction
  | CheckCredentialEmailSuccessAction
  | CheckCredentialEmailFailureAction
  | CheckCredentialMobileSuccessAction
  | CheckCredentialMobileFailureAction;

export type IUsernameCheck = {
  pending: boolean;
  status: IStatus | null;
};

export type SignupState = {
  activeScreen: signupScreens;
  pending: boolean;
  showSignup: boolean;
  status: IStatus | null;
  signUpPayload: ISignupLocalPayload;
  usernameCheck: IUsernameCheck;
  checkEmailCredential: IUsernameCheck;
  checkMobileCredential: IUsernameCheck;
};
