import { IErrorPayload, IStatus } from '../../interfaces/root'
import {
  IConsentsViewModel,
  ISetConestsRequestPayload,
} from '../../interfaces/user'
import { userActionTypes } from '../user'

export type GetConsentsRequestAction = {
  type: userActionTypes.GET_CONSENTS_REQUEST
}

export type GetConsentsSuccessAction = {
  type: userActionTypes.GET_CONSENTS_SUCCESS
  payload: IConsentsViewModel
}

export type GetConsentsFailureAction = {
  type: userActionTypes.GET_CONSENTS_FAILURE
  payload: IErrorPayload
}

export type SetConsentsRequestAction = {
  type: userActionTypes.SET_CONSENTS_REQUEST
  payload: ISetConestsRequestPayload
}

export type SetConsentsSuccessAction = {
  type: userActionTypes.SET_CONSENTS_SUCCESS
  payload: IConsentsViewModel
}

export type SetConsentsFailureAction = {
  type: userActionTypes.SET_CONSENTS_FAILURE
  payload: IErrorPayload
}

export type ClearConsentsAction = {
  type: userActionTypes.CLEAR_CONSENTS
}

export type ConsentsActions =
  | GetConsentsRequestAction
  | GetConsentsSuccessAction
  | GetConsentsFailureAction
  | SetConsentsRequestAction
  | SetConsentsSuccessAction
  | SetConsentsFailureAction
  | ClearConsentsAction

export type ConsentsState = {
  pending: boolean
  data: IConsentsViewModel | null
  status: IStatus | null
  isUpdatePending: boolean
}

export enum consentStatuses {
  NOT_CONSENTED = "NOT_CONSENTED",
  CONSENTED = "CONSENTED",
}