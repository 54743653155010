import { userActionTypes } from '..'
import { IErrorPayload, IStatus } from '../../../interfaces/root'
import { ICancelPendingWithdrawalViewModel, IPendingWithdrawalViewModel } from '../../../interfaces/user'

export enum pendingPendingWithdrawalsalsStatus {
  Success = 'Success',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

export type PendingWithdrawalsRequestAction = {
  type: userActionTypes.PENDING_WITHDRAWALS_REQUEST
}

export type PendingWithdrawalsSuccessAction = {
  type: userActionTypes.PENDING_WITHDRAWALS_SUCCESS
  payload: IPendingWithdrawalViewModel
}

export type PendingWithdrawalsFailureAction = {
  type: userActionTypes.PENDING_WITHDRAWALS_FAILURE
  payload: IErrorPayload
}

export type CancelPendingWithdrawalRequestAction = {
  type: userActionTypes.CANCEL_PENDING_WITHDRAWAL_REQUEST
  payload: string
}

export type CancelPendingWithdrawalSuccessAction = {
  type: userActionTypes.CANCEL_PENDING_WITHDRAWAL_SUCCESS
  payload: ICancelPendingWithdrawalViewModel
}

export type CancelPendingWithdrawalFailureAction = {
  type: userActionTypes.CANCEL_PENDING_WITHDRAWAL_FAILURE
  payload: IErrorPayload
}

export type ClearPendingWithdrawalsAction = {
  type: userActionTypes.CLEAR_PENDING_WITHDRAWALS
}

export type PendingWithdrawalsActions =
  | PendingWithdrawalsRequestAction
  | PendingWithdrawalsSuccessAction
  | PendingWithdrawalsFailureAction
  | CancelPendingWithdrawalRequestAction
  | CancelPendingWithdrawalSuccessAction
  | CancelPendingWithdrawalFailureAction
  | ClearPendingWithdrawalsAction

export type PendingWithdrawalsState = {
  pending: boolean
  data: IPendingWithdrawalViewModel | null
  status: IStatus | null

  cancelWithdrawalPending: boolean
  cancelWithdrawalData: ICancelPendingWithdrawalViewModel | null
  cancelWithdrawalStatus: IStatus | null
}
